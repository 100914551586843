import React from 'react';
import './index.css';
import { Grid } from '@mui/material';

const Banner = () => {
    return (
        <Grid className="banner" id='top'>
            <video autoPlay muted loop className="video">
                <source src="/videos/banner-video.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <Grid item xs={4} className="banner-content">
                <h1>Dove Test de Piel</h1>
                <p>Crea tu Rutina de Cuidado y disfruta de 48h de Hidratación Regenerativa con los Nuevos Sérums Corporales</p>
                <a href="#quiz">
                    <button className='banner-button'>Comenzar el test</button>
                </a>
            </Grid>
            <Grid item  xs={4} className='image-container'>
                <img
                    className="overlay-image2"
                    src="images/compose-2.png"
                    alt="Imagem 2"
                />
            </Grid>
        </Grid>
    );
};

export default Banner;
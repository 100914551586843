// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-container {
    color: #767676;
    font-family: 'Sophia Pro Light';
    margin-top: 86px;
    display: flex;
    width: 100%;
    padding: 24px 0px;
    flex-direction: column;
    align-items: center;
    gap: 37px;
    border-top: 1px solid #767676;
    background: #F9F9FA;

    .social-icons {
        display: flex;
        gap: 16px;
    }

    .infos {
        display: flex;
        gap: 56px;

        @media (max-width: 768px) {
            flex-direction: column;
            gap: 16px;
            text-align: center;
        }

        .link {
            text-decoration: none;
            color: #767676;
            font-family: 'Sofia Pro Light';
            font-size: 18px;
        }
    }

    .unilever {
        color: #767676;
        font-family: 'Sofia Pro Light';
        font-size: 18px;

        .unilever-image {
            vertical-align: middle;
        }
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/index.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,+BAA+B;IAC/B,gBAAgB;IAChB,aAAa;IACb,WAAW;IACX,iBAAiB;IACjB,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;IACT,6BAA6B;IAC7B,mBAAmB;;IAEnB;QACI,aAAa;QACb,SAAS;IACb;;IAEA;QACI,aAAa;QACb,SAAS;;QAET;YACI,sBAAsB;YACtB,SAAS;YACT,kBAAkB;QACtB;;QAEA;YACI,qBAAqB;YACrB,cAAc;YACd,8BAA8B;YAC9B,eAAe;QACnB;IACJ;;IAEA;QACI,cAAc;QACd,8BAA8B;QAC9B,eAAe;;QAEf;YACI,sBAAsB;QAC1B;IACJ;AACJ","sourcesContent":[".footer-container {\r\n    color: #767676;\r\n    font-family: 'Sophia Pro Light';\r\n    margin-top: 86px;\r\n    display: flex;\r\n    width: 100%;\r\n    padding: 24px 0px;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    gap: 37px;\r\n    border-top: 1px solid #767676;\r\n    background: #F9F9FA;\r\n\r\n    .social-icons {\r\n        display: flex;\r\n        gap: 16px;\r\n    }\r\n\r\n    .infos {\r\n        display: flex;\r\n        gap: 56px;\r\n\r\n        @media (max-width: 768px) {\r\n            flex-direction: column;\r\n            gap: 16px;\r\n            text-align: center;\r\n        }\r\n\r\n        .link {\r\n            text-decoration: none;\r\n            color: #767676;\r\n            font-family: 'Sofia Pro Light';\r\n            font-size: 18px;\r\n        }\r\n    }\r\n\r\n    .unilever {\r\n        color: #767676;\r\n        font-family: 'Sofia Pro Light';\r\n        font-size: 18px;\r\n\r\n        .unilever-image {\r\n            vertical-align: middle;\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

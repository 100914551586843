import React from 'react';
import './index.css';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container'
import Card from '@mui/material/Card';
import { Typography } from '@mui/material';

const Cards = () => {
    return (
        <Container className="cards-container">
            <h2>Cómo funciona el test</h2>
            <Typography variant="body2" className='paragraph'>Descubre los cuidados especiales para tu piel en tan solo 3 pasos</Typography>
            <Grid container columnSpacing={2} rowSpacing={2}>
                <Grid item xs={12} sm={4}>
                    <Card elevation={0} className='card'>
                        <Typography variant="h6" component="h3">
                            Análisis personalizado
                        </Typography>
                        <hr />
                        <Typography variant="body2">
                            Comienza respondiendo algunas preguntas simples, para ayudarnos a entender las
                            necesidades únicas de tu piel en menos de 2 minutos.
                        </Typography>
                    </Card>

                </Grid>
                <Grid item xs={12} sm={4} >
                    <Card elevation={0} className='card'>
                        <Typography variant="h6" component="h3">
                            Descubre tu tipo de piel
                        </Typography>
                        <hr />
                        <Typography variant="body2">
                            Basado en tus respuestas, identificaremos tu tipo de piel (grasa, seca, extra seca o
                            mixta) y tus necesidades específicas.
                        </Typography>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Card elevation={0} className='card'>
                        <Typography variant="h6" component="h3">
                            Recibe tu rutina ideal
                        </Typography>
                        <hr />
                        <Typography variant="body2">
                            Y finalmente, recibirás una rutina de cuidado personalizada, para iniciar tu camino hacia una piel más hidratada y uniforme con los Sérums Dove.
                        </Typography>
                    </Card>
                </Grid>
            </Grid>
        </Container>

    );
};

export default Cards;
import React from 'react';
import './index.css'

const Footer = () => {

    /*const links = {
        contact: '#contact',
        privacyNotice: isFromMexico
            ? 'https://www.unilevernotices.com/privacy-notices/mexico-spanish.html'
            : 'https://www.unilevernotices.com/privacy-notices/argentina-spanish.html',
        termsAndConditions: isFromMexico
            ? 'https://www.unilever-northlatam.com/legal/'
            : 'https://www.unilever-southlatam.com/legal/',
        accessibility: isFromMexico
            ? 'https://notices.unilever.com/es/mx/'
            : 'https://notices.unilever.com/es/ar/',
        facebook: isFromMexico
            ? 'https://www.facebook.com/DoveMexico/'
            : 'https://www.facebook.com/DoveBr/?brand_redir=484185711646092',
        youtube: isFromMexico
            ? 'https://www.youtube.com/user/dovemexico'
            : 'https://www.youtube.com/user/DoveArgentina',
        x: isFromMexico
            ? 'https://x.com/Dove_Mex?mx=2'
            : 'https://x.com/dove_ar'
    };*/

    // Refactor LINKS - Harisson
    function getCountry() {
        const userLanguage = navigator.language || navigator.userLanguage;
        console.log('userLanguage :: ', userLanguage);
        if (userLanguage.startsWith('es-MX')) return 'mexico';
        if (userLanguage.startsWith('es-AR')) return 'argentina';
        if (userLanguage.startsWith('es-CO')) return 'colombia';
        if (userLanguage.startsWith('pt-BR')) return 'mexico';
    }

    const countryLinks = {
        mexico: {
            contact: '#contact',
            privacyNotice: 'https://www.unilevernotices.com/privacy-notices/mexico-spanish.html',
            termsAndConditions: 'https://www.unilever-northlatam.com/legal/',
            accessibility: 'https://notices.unilever.com/es/mx/',
            facebook: 'https://www.facebook.com/DoveMexico/',
            youtube: 'https://www.youtube.com/user/dovemexico',
            x: 'https://x.com/Dove_Mex?mx=2',
            accessLP: 'http://www.analiza-tu-piel.dove.com/MX',
            shopping: 'https://www.amazon.com.mx/stores/page/6F4E9D65-EF04-4A40-82DA-B40DEB23EE6C',
        },
        argentina: {
            contact: '#contact',
            privacyNotice: 'https://www.unilevernotices.com/privacy-notices/argentina-spanish.html',
            termsAndConditions: 'https://www.unilever-southlatam.com/legal/',
            accessibility: 'https://notices.unilever.com/es/ar/',
            facebook: 'https://www.facebook.com/DoveBr/?brand_redir=484185711646092',
            youtube: 'https://www.youtube.com/user/DoveArgentina',
            x: 'https://x.com/dove_ar',
            accessLP: 'https://www.analiza-tu-piel.dove.com/AR',
            shopping: 'https://www.dove.com/ar/home.html',
        },
        colombia: {
            contact: '#contact',
            privacyNotice: 'https://www.unilevernotices.com/privacy-notices/argentina-spanish.html',
            termsAndConditions: 'https://www.unilever-southlatam.com/legal/',
            accessibility: 'https://notices.unilever.com/es/ar/',
            facebook: 'https://www.facebook.com/DoveMiddleAmericas/?locale=es_LA',
            youtube: 'https://www.youtube.com/@DoveMiddleAmericaDMA',
            x: 'https://x.com/Dove_LATAM',
            accessLP: 'https://www.analiza-tu-piel.dove.com/CO',
            shopping: 'https://www.dove.com/co/cremas-corporales.html',
        }
    };

    const country = getCountry();
    console.log('country :: ', country);
    const links = countryLinks[country];
    console.log('LINKS :: ', links);
    // Refactor LINKS - Harisson

    return (
        <footer className="footer-container">
            <img alt='bird' src='/icons/dove.svg' />
            <div className='social-icons'>
                <a href={links?.facebook} target='_blank'>
                    <img alt='facebook' src='/icons/facebook.svg' />
                </a>
                <a href='https://www.instagram.com/dove/' target='_blank'><img alt='instagram' src='/icons/instagram.svg' /></a>
                <a href={links?.x} target='_blank'>
                    <img alt='x' src='/icons/x.svg' />
                </a>
                <a href={links?.youtube} target='_blank'>
                    <img alt='youtube' src='/icons/youtube.svg' />
                </a>
            </div>
            <div className='infos'>
                <a className='link' href={links?.privacyNotice || '#'} target='_blank' rel="noopener noreferrer">Aviso de Privacidad</a>
                <a className='link' href={links?.termsAndConditions || '#'} target='_blank' rel="noopener noreferrer">Términos y condiciones</a>
                <a className='link' href={links?.accessibility || '#'} target='_blank' rel="noopener noreferrer">Accesibilidad</a>
            </div>
            <div className='unilever'>
                <img alt='unilever' className='unilever-image' src='/icons/Unilever.svg' />© 2024 Unilever
            </div>
        </footer>
    );
};

export default Footer;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quiz-button {
    width: auto;
    display: flex;
    height: auto;
    background: #FFFFFF !important;
    border-radius: 2px !important;
    font-family: 'Sofia Pro Light' !important;
    font-style: normal;
    border: 1px solid #C59531 !important;
    font-weight: 300;
    font-size: 16px !important;
    line-height: 16px;
    color: #001F5F !important;
    position: relative;
    padding: 12px 24px;
    cursor: pointer;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background-image: linear-gradient(270deg, #C59531 0%, #FEEAB8 50%, #C59531 100%);
    }

    &:hover {
        background: #C59531 !important;
        color: #FFFFFF !important;
    }

    &.selected-button {
        background: #C59531 !important;
        color: white !important;
    }

}`, "",{"version":3,"sources":["webpack://./src/components/OptionButton/index.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,YAAY;IACZ,8BAA8B;IAC9B,6BAA6B;IAC7B,yCAAyC;IACzC,kBAAkB;IAClB,oCAAoC;IACpC,gBAAgB;IAChB,0BAA0B;IAC1B,iBAAiB;IACjB,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;;IAEf;QACI,WAAW;QACX,kBAAkB;QAClB,SAAS;QACT,OAAO;QACP,WAAW;QACX,WAAW;QACX,gFAAgF;IACpF;;IAEA;QACI,8BAA8B;QAC9B,yBAAyB;IAC7B;;IAEA;QACI,8BAA8B;QAC9B,uBAAuB;IAC3B;;AAEJ","sourcesContent":[".quiz-button {\r\n    width: auto;\r\n    display: flex;\r\n    height: auto;\r\n    background: #FFFFFF !important;\r\n    border-radius: 2px !important;\r\n    font-family: 'Sofia Pro Light' !important;\r\n    font-style: normal;\r\n    border: 1px solid #C59531 !important;\r\n    font-weight: 300;\r\n    font-size: 16px !important;\r\n    line-height: 16px;\r\n    color: #001F5F !important;\r\n    position: relative;\r\n    padding: 12px 24px;\r\n    cursor: pointer;\r\n\r\n    &::after {\r\n        content: '';\r\n        position: absolute;\r\n        bottom: 0;\r\n        left: 0;\r\n        width: 100%;\r\n        height: 3px;\r\n        background-image: linear-gradient(270deg, #C59531 0%, #FEEAB8 50%, #C59531 100%);\r\n    }\r\n\r\n    &:hover {\r\n        background: #C59531 !important;\r\n        color: #FFFFFF !important;\r\n    }\r\n\r\n    &.selected-button {\r\n        background: #C59531 !important;\r\n        color: white !important;\r\n    }\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

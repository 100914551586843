import React from 'react';

import './index.css'
const Navbar = () => {
    return (
        <header className="navbar">
            <div className='logo-container'>
                <a href='#top'>
                    <img className='logo' src="./images/bird.svg" alt="bird logo" />
                </a>
            </div>
        </header>
    );
}

export default Navbar;

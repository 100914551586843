import React from 'react';
import { Box, Typography } from '@mui/material';
import './index.css'

const ChatBubble = ({ text, sender }) => {
    return (
        <Box className="chat-bubble-container" sx={{ flexDirection: `${sender === 'user' ? 'row-reverse' : 'row'}` }}>
            <img className={`${sender === 'user' ? 'avatar-user' : 'avatar-bot'}`} alt='avatar' src={`${sender === 'user' ? '/images/CircleUser.svg' : '/images/CircleBot.svg'}`} />
            <Box className="message-container">
                <Typography dangerouslySetInnerHTML={{ __html: text }} className={`${sender === 'user' ? 'user-message' : 'bot-message'}`} variant="body1" style={{ marginBottom: 10 }} />
                <div className={`chat-bubble-tip-${sender === 'user' ? 'right' : 'left'}`} />
            </Box>
        </Box>
    );
};

export default ChatBubble;

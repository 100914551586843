// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 72px;
    background: #fff;
    box-shadow: 0px 2px 3px 0px rgba(182, 182, 182, 0.4);
    width: 100%;
    z-index: 999;
    position: fixed;
}

.logo-container {
    width: 12rem;
    height: 9rem;
    border-radius: 0 0 50% 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    box-shadow: 0px 5px 2px -3px rgba(182, 182, 182, 0.4);
    position: relative;
    z-index: 1;
}

.logo {
    cursor: pointer;
    object-fit: cover;
    position: absolute;
    top: 58%;
    left: 50%;
    transform: translate(-50%, -50%);
}`, "",{"version":3,"sources":["webpack://./src/components/Navbar/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;IAChB,oDAAoD;IACpD,WAAW;IACX,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,0BAA0B;IAC1B,sBAAsB;IACtB,aAAa;IACb,mBAAmB;IACnB,qDAAqD;IACrD,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;AACpC","sourcesContent":[".navbar {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    height: 72px;\r\n    background: #fff;\r\n    box-shadow: 0px 2px 3px 0px rgba(182, 182, 182, 0.4);\r\n    width: 100%;\r\n    z-index: 999;\r\n    position: fixed;\r\n}\r\n\r\n.logo-container {\r\n    width: 12rem;\r\n    height: 9rem;\r\n    border-radius: 0 0 50% 50%;\r\n    background-color: #fff;\r\n    display: flex;\r\n    align-items: center;\r\n    box-shadow: 0px 5px 2px -3px rgba(182, 182, 182, 0.4);\r\n    position: relative;\r\n    z-index: 1;\r\n}\r\n\r\n.logo {\r\n    cursor: pointer;\r\n    object-fit: cover;\r\n    position: absolute;\r\n    top: 58%;\r\n    left: 50%;\r\n    transform: translate(-50%, -50%);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
